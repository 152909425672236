import React from "react"
import ReactDOM from "react-dom"

//MSAL Components
import { MsalProvider } from "@azure/msal-react"
import { PublicClientApplication } from "@azure/msal-browser"
import { msalConfig } from "./Components/Auth/AuthConfig"

//Import components
import "@stackoverflow/stacks/dist/css/stacks.min.css"
import "./assets/styles/main.css"
import Main from "./App"

export const msalInstance = new PublicClientApplication(msalConfig)

const accounts = msalInstance.getAllAccounts()
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0])
}

const rootElement = document.getElementById("root")
ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <Main />
  </MsalProvider>,
  rootElement
)
